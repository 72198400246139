<template>
  <div class="container">
    <b-tabs
      card
      style="font-size: 20px;"
    >
      <b-tab
        class="p-0"
        title="Part 1"
      >
        <performance-part-1 />
      </b-tab>
      <b-tab
        class="p-0"
        title="Part 2"
      >
        <performance-part-2 />
      </b-tab>
      <b-tab
        class="p-0"
        title="Part 3"
      >
        <performance-part-3 />
      </b-tab>
      <b-tab
        class="p-0"
        title="Part 4"
      >
        <performance-part-4 />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import {
  BTabs,
  BTab,
  // BButtonGroup,
  // BCardHeader,
  // BButton,
  //   BButtonGroup,
  //   BNav,
  //   BNavItem,

  //   BLink,
} from "bootstrap-vue"
import PerformancePart1 from "@/pages/exec/JD-KRA-User/KRA/Part-1-new.vue"
import PerformancePart2 from "@/pages/exec/JD-KRA-User/KRA/Part-2.vue"
import PerformancePart3 from "@/pages/exec/JD-KRA-User/KRA/Part-3.vue"
import PerformancePart4 from "@/pages/exec/JD-KRA-User/KRA/Part-4.vue"
// import AppTabs from "@/pages/AppTabs.vue"

export default {
  components: {
    BTabs,
    BTab,
    // BButtonGroup,
    // BCardHeader,
    // BButton,
    // BButtonGroup,
    // BNav,
    // BNavItem,
    // BLink,

    PerformancePart1,
    PerformancePart2,
    PerformancePart3,
    PerformancePart4,
    // AppTabs,
  },
  data() {
    return { tabList: ["Tab 1", "Tab 2", "Tab 3", "Tab 4"] }
  },
}
</script>
