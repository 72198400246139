<template>
  <div class="mt-3">
    <!-- <div><target /></div>
    <div><achievement /></div>
    <div><tabs /></div> -->
    <section id="card-navigation">
      <b-row>
        <b-col
          style="display: flex; justify-content: flex-start"
        >
          <router-link :to="{name: 'scorecard'}">
            <b-button
              class="m-0 mb-3"
              variant="info"
              style="
                    font-family: 'Poppins';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 15px;
                  "
            >
              {{ $t("View Scorecard") }}

            </b-button>
          </router-link>
        </b-col>
        <b-col md="12">
          <b-card
            class=""
            style="
              background: #ffffff;
              box-shadow: 0px 0px 41px 9px rgba(0, 0, 0, 0.11);
              border-radius: 20px;
            "
          >
            <b-tabs
              nav-class="tab-title m-0"
              style="
                font-family: Poppins;
                font-style: normal;
                font-weight: 600;
                font-size: 21px;
                line-height: 24px;
                letter-spacing: 0.1em;
              "
            >
              <b-tab
                class="p-0"
                title="Part 1"
              >
                <!-- <partplanning /> -->
                <div class=" mt-3 ">
                  <b-card style="border-radius: 20px">
                    <b-card-body class="">
                      <b-form
                        ref="form"
                        class="repeater-form"
                        :disabled="disableInput"
                      >
                        <b-form-group label-for="textBox">
                          <div
                            ref="row"
                            class="mb-2 mt-2 ml-2 mr-2"
                            style="
                        <!-- background: #f7f7fc; -->
                        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
                          0px 0px 41px 9px rgba(0, 0, 0, 0.11);
                        border-radius: 20px;
                        height: 100%;
                      "
                          >
                            <b-col
                              cols="11"
                              class=""
                              style="
              <!-- background:lightgreen -->
              "
                            >
                              <!-- part1 -->
                              <div class="m-0 mb-2 mt-2">
                                <span
                                  class="d-flex"
                                  style="
              font-family: Poppins;
              font-style: normal;
              font-weight: 500;
              font-size: 23px;
              line-height: 30px;
              letter-spacing: 0.4px;
              color: #000000;
            "
                                >

                                  {{ $t("PART 1 ") }}:{{ $t("KEY RESULT AREA") }}(KRAs){{ ("PLANNING") }}
                                </span>
                              </div>
                              <div class="mt-1 mb-1">
                                <b-form-group label-for="textBox">
                                  <b-col class="mt-1 mb-1">
                                    <b-row>
                                      <!-- {{ keyResult }} -->
                                      <span
                                        style="
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 400;
                font-size: 21px;
                line-height: 30px;
                letter-spacing: 0.4px;
                color: #000000;
              "
                                      >
                                        {{ $t("Departmental Balanced Scorecard") }}
                                      </span>
                                    </b-row>
                                    <b-row>
                                      <b-form-textarea
                                        v-model="keyResult.scorecard_name"
                                        :disabled="disableInput"
                                        style="font-size: 21px;"
                                      />
                                    </b-row>
                                  </b-col>
                                  <b-col class="mt-1 mb-1">
                                    <b-row>
                                      <span
                                        style="
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 400;
                font-size: 21px;
                line-height: 30px;
                letter-spacing: 0.4px;
                color: #000000;
              "
                                      >
                                        {{ $t("Objective") }}/{{ $t("Initiative") }}
                                      </span>
                                    </b-row>
                                    <b-row>
                                      <b-form-textarea
                                        v-model="keyResult.dbs_initiative"
                                        :disabled="disableInput"
                                        style="font-size: 21px;"
                                      />
                                    </b-row>
                                  </b-col>
                                  <b-col class="mt-1 mb-1">
                                    <b-row>
                                      <span
                                        style="
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 400;
                font-size: 21px;
                line-height: 30px;
                letter-spacing: 0.4px;
                color: #000000;
              "
                                      >
                                        {{ $t("Key Result Area") }}
                                      </span>
                                    </b-row>
                                    <b-row>

                                      <b-form-textarea
                                        v-model="keyResult.dbs_kra"

                                        :disabled="disableInput"
                                        style="font-size: 21px;"
                                      />
                                    </b-row>
                                  </b-col>
                                  <b-col class="mt-1 mb-1">
                                    <b-row>
                                      <span
                                        style="
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 400;
                font-size: 21px;
                line-height: 30px;
                letter-spacing: 0.4px;
                color: #000000;
              "
                                      >
                                        {{ $t("Measurement") }}
                                      </span>
                                    </b-row>
                                    <b-row>
                                      <b-form-textarea
                                        v-model="keyResult.dbs_measurement"
                                        :disabled="disableInput"
                                        style="font-size: 21px;"
                                      />
                                    </b-row>
                                  </b-col>
                                  <b-col class="mt-1 mb-1">
                                    <b-row>
                                      <span
                                        style="
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 400;
                font-size: 21px;
                line-height: 30px;
                letter-spacing: 0.4px;
                color: #000000;
              "
                                      >
                                        {{ $t("Target") }}&{{ $t("Deadline") }}
                                      </span>
                                    </b-row>
                                    <b-row>
                                      <b-form-textarea
                                        v-model="keyResult.dbs_target"
                                        :disabled="disableInput"
                                        style="font-size: 21px;"
                                      />
                                    </b-row>
                                  </b-col>
                                </b-form-group>
                              </div>
                              <b-col
                                class="p-0"
                                style="display: flex; justify-content: flex-end"
                              >
                                <b-button
                                  class="m-0"
                                  variant="primary"
                                  style="
                    font-family: 'Poppins';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 15px;
                  "
                                  @click="toggleInput"
                                >
                                  {{ $t("Edit") }}
                                </b-button>
                              </b-col>
                            </b-col>
                          </div>
                        </b-form-group>
                      </b-form>
                    </b-card-body>
                  </b-card>
                </div>
              </b-tab>
              <b-tab
                class="p-0"
                title="Part 2a"
              >
                <partreview
                  :key-result="keyResult"
                  @addItemInKeyResult="addItemInKeyResult"
                />
              </b-tab>
              <b-tab
                class="p-0"
                title="Part 2b"
              >
                <partresult
                  :key-result="keyResult"
                />
              </b-tab>
            </b-tabs>
          </b-card>
        </b-col>
      </b-row>
    </section>
  </div>
</template>

<script>
import {
  BTabs,
  BTab,
  BRow,
  BCol,
  BCard,
  // BButtonGroup,
  // BCardHeader,
  BButton,
  //   BButtonGroup,
  //   BNav,
  //   BNavItem,

  //   BLink,
  BFormTextarea,
  BCardBody,
  BForm,
  BFormGroup,
} from "bootstrap-vue"
// import target from "@/component/33-target.vue"
// import achievement from '@/component/34-achievement.vue'
// import tabs from '@/component/30-tabs.vue'
// import Partplanning from '@/pages/exec/KRA-new/KRA-planning.vue'
// import Partreview from '@/pages/exec/KRA-new/KRA-review.vue'
// import Partresult from '@/pages/exec/KRA-new/KRA-result.vue'
// import AppTabs from "@/pages/AppTabs.vue"

export default {
  components: {
    BTabs,
    BTab,
    BRow,
    BCol,
    BCard,
    // BButtonGroup,
    // BCardHeader,
    BButton,
    // BButtonGroup,
    // BNav,
    // BNavItem,
    // BLink,
    // AppTabs,
    // Partplanning,
    BFormTextarea,
    BCardBody,
    BForm,
    BFormGroup,
    // eslint-disable-next-line vue/no-unused-components
    // target,
    // achievement,
    // tabs,
    // Partreview,
    // eslint-disable-next-line vue/no-unused-components
    // Partresult,
  },
  data() {
    return {
      tabList: ["Tab 1", "Tab 2", "Tab 3"],

      keyResult: {
        scorecard_name: "",
        dbs_initiative: "",
        dbs_kra: "",
        dbs_measurement: "",
        dbs_target: "",
        achievement_date: "",
        items: [{
          id: 1,
          kra_tracking: '',
          key_performance_index: '',
          rating: 0,
          remark: '',
        }],
      },

      total_score: null,

      disableInput: true,
      disableRating: true,
      isModalVisible: false,
      // KRAArray: [
      //   {
      //     kra_tracking: [],
      //     key_performance_index: [],
      //     rating: [],
      //     remarks: [],
      //   },
      // ],
    }
  },
  mounted(){
  },
  methods: {
    addItemInKeyResult(id){
      this.keyResult.items.push(
        {
          id,
          kra_tracking: '',
          key_performance_index: '',
          remark: '',
          rating: 0,
        },
      )
    },
    // repeateAgain() {
    //   this.items.push({
    //     id: (this.nextTodoId += this.nextTodoId),
    //   })
    //   console.log(this.items)
    //   this.$nextTick(() => {
    //     this.trAddHeight(this.$refs.row[0].offsetHeight)
    //   })
    // },
    toggleInput() {
      this.disableInput = !this.disableInput
      this.disableRating = !this.disableRating
    },

    showModal() {
      this.isModalVisible = true
    },
    closeModal() {
      this.isModalVisible = false
    },
  },
}
</script>
